import React from "react";
import "../components/Global/global.scss";
import { Col, Container, Row } from "react-bootstrap";
import DataTableInforme from "../components/Informe/Informe";
import Navigation from "../components/Navigation/Navigation";

export default function CheckProjects() {
    return (
        <div>
            <Navigation />
            {/* <SecondaryNavigationEmpty title="Compras" /> */}
            <Container>
                <Row>
                    <Col className="mt-4">
                        <div className="box-page">
                            <DataTableInforme />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
