import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext"; // Importar InputText para el buscador
import "primereact/resources/primereact.min.css"; // Estilos de PrimeReact
import "primeicons/primeicons.css"; // Iconos de PrimeReact
import "primereact/resources/themes/bootstrap4-light-purple/theme.css";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import api from '../../helpers/api';

export default function MyDataTable() {
    const [compras, setCompras] = useState([]);
    const [entradas, setEntradas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(""); // Estado para el valor del filtro de búsqueda
    const dtEntradas = useRef(null);
    const dtCompras = useRef(null);
    const [key, setKey] = useState('compras');

    useEffect(() => {
        setLoading(true)
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const fetchData = async () => {
        try {
            const compras = await api.getComprasInforme(localStorage.getItem('token'));
            const entradas = await api.getEntradasInforme(localStorage.getItem('token'));
            formatData(compras.data.reverse())
            setEntradas(entradas.data.reverse())
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        setLoading(false)
    };

const formatData = (data) =>{
    for(let transaction of data){
        let number = transaction.monto*1
        transaction.formatMonto = `$${number.toLocaleString('de-DE')}`
    }
    setCompras(data);
}

const comprasTable = ()=>{
    return <>
    <div className="p-input-icon-left m-3 w-100 pe-5">
        <InputText
            type="search"
            placeholder="Buscar..."
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            className="w-100"
        />
    </div>
    {!!loading ? 
        <div className="w-100 d-flex justify-content-center mb-4">
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
        </div>
    :
        <DataTable
            ref={dtCompras}
            value={compras}
            paginator
            rowsPerPageOptions={[5,10,25,50,100]}
            stripedRows
            rows={5}
            globalFilter={globalFilter}
            className="custom-datatable striped bordered hover"
        >
            <Column sortable key='id' field='id' header='Id' />
            <Column key='order_id' field='order_id' header='Id woo' />
            <Column key='Id_Cliente' field='Id_Cliente' header='Cliente' />
            <Column key='nombre' field='nombre' header='Nombre' />
            <Column key='apellido' field='apellido' header='Apellido' />
            <Column key='formatMonto' field='formatMonto' header='Monto' />
            <Column key='email' field='email' header='Email' />
            <Column key='dni' field='dni' header='Dni' />
            <Column key='provincia' field='provincia' header='Provincia' />
            <Column key='ciudad' field='ciudad' header='Ciudad' />
            <Column key='estado' field='estado' header='Estado' />
            <Column key='cantidad' field='cantidad' header='Cantidad' />
            <Column key='fecha' field='fecha' header='Fecha'  />
        </DataTable>  
    }
    </>
}

const entradasTable = () =>{
    return <>
        <div className="p-input-icon-left m-3 w-100 pe-5">
                <InputText
                    type="search"
                    placeholder="Buscar..."
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    className="w-100"
                />
            </div>
            {!!loading ? 
                <div className="w-100 d-flex justify-content-center mb-4">
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                </div>
            :
                <DataTable
                    ref={dtEntradas}
                    value={entradas}
                    paginator
                    rowsPerPageOptions={[5,10,25,50,100]}
                    stripedRows
                    rows={5}
                    globalFilter={globalFilter}
                    className="custom-datatable striped bordered hover"
                >
                    <Column sortable key='idEntrada' field='idEntrada' header='Id' />
                    <Column key='order_id' field='order_id' header='Id woo' />
                    <Column key='idCliente' field='idCliente' header='Cliente' />
                    <Column key='idCompra' field='idCompra' header='Compra' />
                    <Column key='nombre' field='nombre' header='Nombre' />
                    <Column key='apellido' field='apellido' header='Apellido' />
                    <Column key='email' field='email' header='Email' />
                    <Column key='dni' field='dni' header='Dni' />
                    <Column key='provincia' field='provincia' header='Provincia' />
                    <Column key='ciudad' field='ciudad' header='Ciudad' />
                    <Column key='zona' field='zona' header='Zona' />
                    <Column key='fila' field='fila' header='Fila' />
                    <Column key='asiento' field='asiento' header='Asiento' />
                    <Column key='bingo' field='bingo' header='Bingo' />
                    <Column key='estado' field='estado' header='Estado' />
                    <Column key='fecha' field='fecha' header='Fecha'  />
                </DataTable>  
            }
    </>
}

return (
    <>
        <div className="w-100 d-flex justify-content-between">
            <h5>Informes</h5>
            <div>
                <button className="btn btn-primary mb-2 me-2" onClick={() => {key === 'compras' ? dtCompras.current.exportCSV() : dtEntradas.current.exportCSV()}}>Descargar</button>
            </div>
        </div>
        <div className="card">
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
            className="mb-3"
            >
                <Tab eventKey="compras" title="Compras">
                    {comprasTable()}
                </Tab>
                <Tab eventKey="entradas" title="Entradas">
                    {entradasTable()}
                </Tab>
                
            </Tabs>
        </div>
    </>
);
}
